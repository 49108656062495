import React from "react";
import { Col, Row } from "react-bootstrap";
import { DiAws, DiDocker, DiGit } from "react-icons/di";
import {
  SiVisualstudiocode,
  SiPostman,
  SiSlack,
  SiVercel,
  SiMacos,
} from "react-icons/si";

function Toolstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <DiGit />
        <span style={{fontSize: '20px'}}>Git</span>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiDocker />
        <span style={{fontSize: '20px'}}>Docker</span>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiAws />
        <span style={{fontSize: '20px'}}>AWS</span>
      </Col>
    </Row>
  );
}

export default Toolstack;
