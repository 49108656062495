import React from "react";
import { Col, Row } from "react-bootstrap";
import { CgCPlusPlus } from "react-icons/cg";
import {
  DiJavascript1,
  DiReact,
  DiNodejs,
  DiMongodb,
  DiPython,
  DiGit,
  DiJava,
  DiDocker,
  DiAngularSimple,
  DiMysql,
  DiAws,
  DiHtml5,
} from "react-icons/di";
import {
  SiRedis,
  SiFirebase,
  SiNextdotjs,
  SiSolidity,
  SiPostgresql,
  SiSpring,
  SiSpringboot,
  SiFlask,
  SiExpress,
  SiSequelize,
  SiTypescript,
  SiRedux,
  SiCss3,
} from "react-icons/si";
import { TbBrandGolang } from "react-icons/tb";

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      {/* coding languages */}
      <Col xs={4} md={2} className="tech-icons">
        <DiJava />
        <span style={{ fontSize: '20px' }}>Java</span>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiJavascript1 />
        <span style={{ fontSize: '20px' }}>JavaScript</span>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiTypescript />
        <span style={{ fontSize: '18px' }}> TypeScript</span>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiPython />
        <span style={{ fontSize: '20px' }}>Python</span>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiHtml5 />
        <span style={{ fontSize: '20px' }}>Html</span>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiCss3 />
        <span style={{ fontSize: '20px' }}>CSS</span>
      </Col>

      {/* front-end techniques */}
      <Col xs={4} md={2} className="tech-icons">
        <DiReact />
        <span style={{ fontSize: '20px' }}>React</span>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiRedux />
        <span style={{ fontSize: '20px' }}>Redux</span>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiAngularSimple />
        <span style={{ fontSize: '20px' }}>Angular</span>
      </Col>

      {/* backend techniques */}
      <Col xs={4} md={2} className="tech-icons">
        <SiSpring />
        <span style={{ fontSize: '16px' }}>Spring</span>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiSpringboot />
        <span style={{ fontSize: '16px' }}>SpringBoot</span>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiExpress />
        <span style={{ fontSize: '20px' }}>Express</span>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiNodejs />
        <span style={{ fontSize: '20px' }}>Node.js</span>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiSequelize />
        <span style={{ fontSize: '20px' }}>Sequelize</span>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiFlask />
        <span style={{ fontSize: '20px' }}>Flask</span>
      </Col>


      {/* databases */}
      <Col xs={4} md={2} className="tech-icons">
        <SiPostgresql />
        <span style={{ fontSize: '16px' }}>PostgreSQL</span>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiMysql />
        <span style={{ fontSize: '20px' }}>Mysql</span>
      </Col>

    </Row>
  );
}

export default Techstack;
