import React from "react";
import Card from "react-bootstrap/Card";
import { AiFillGithub } from "react-icons/ai";
import { FaLinkedin } from "react-icons/fa";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  // const recipientEmail = 'ccnuxuji@gmail.com';
  // const subject = 'Hello';
  // const body = 'I hope this email finds you well.';
  // const mailtoLink = `mailto:${recipientEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Jimmy Xu </span>
            from <span className="purple"> San Jose, California.</span>
            <br /> I am a Full Stack Software Engineer looking for a full-time position based in the bay area. I worked as a data analyst for one and half years with a background of Ph.D in particle physics. My unique combination of scientific rigor and software development expertise allows me to approach complex challenges with a thoughtful mindset and innovative solutions. I am excited to bring my diverse skill set and passion for problem-solving to a full-time position in the Bay Area's dynamic tech landscape.
            <br />
            <br />
            Apart from coding, some other activities that I love to do!
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Walking dogs
            </li>
            <li className="about-activity">
              <ImPointRight /> Playing Games
            </li>
            <li className="about-activity">
              <ImPointRight /> Watching animation
            </li>
            <li className="about-activity">
              <ImPointRight /> Travelling
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            "Better late than never."{" "}
          </p>
          {/* <footer className="blockquote-footer">Jimmy Xu</footer> */}

          <p>Contact me:  ccnuxuji@gmail.com , (510) 325 0203,
              <ul className="footer-icons">
                <li className="social-icons">
                  <a
                    href="https://github.com/ccnuxuji"
                    style={{ color: "white" }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <AiFillGithub />
                  </a>
                </li>

                <li className="social-icons">
                  <a
                    href="https://www.linkedin.com/in/ccnuxuji/"
                    style={{ color: "white" }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaLinkedin />
                  </a>
                </li>
              </ul>
          </p>

        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
